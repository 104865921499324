<template>
    <div>
      <city-comp :type="type"></city-comp>
    </div>
</template>

<script>
import {defineComponent, onMounted, ref} from "vue";
import {setCurrentPageBreadcrumbs} from "@/core/helpers/breadcrumb";
import {getIllustrationsPath} from "@/core/helpers/assets";
import CityComp from "@/components/admin/city/CityComp";
import {checkPermission} from "@/core/helpers/general";

export default defineComponent({
    name: "view-city",
    components : {CityComp},

    setup() {

        const type = ref('view');
        onMounted(() => {
            setCurrentPageBreadcrumbs("View City", ["Cities"] , checkPermission('cities.store') ? 'create-city' : null);
        });


        return {
            getIllustrationsPath,
            type,
        };
    },

});
</script>
